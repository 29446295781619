import Bg from "../assets/misc/casestudy.svg";
import Caseimg from "../assets/artwork/casestudy.svg";

const CaseStudy = () => {
  return (
    <section
      className="h-[1056px] flex items-center"
      style={{
        backgroundImage: `url(${Bg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="white container mx-auto relative z-20">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-20">
          <div className="col-span-1 flex flex-col md:mt-16">
            <div className="flex flex-col gap-y-7">
              <div>
                <h3 className="font-bold text-2xl mb-2 text-main">
                  FREE CASE STUDY:
                </h3>
                <h3 className="font-extrabold text-4xl">
                  Our Approach in Action
                </h3>
              </div>
              <p className="leading-[1.7]">
                Unveiling a track record of consistently delivering exceptional
                ROI for our valued clients.
              </p>
              <p className="leading-[1.7]">
                Harnessing an omnipotent market presence to surpass your
                competition effortlessly.
              </p>
              <p className="leading-[1.7]">
                Recognizing the pivotal role of fortifying your sales process to
                optimize traffic generation.
              </p>
              <button>
                <h3 className="text-lg">VIEW THE FREE CASE STUDY</h3>
              </button>
            </div>
          </div>
          <img
            src={Caseimg}
            className="md:absolute right-[20px] pointer-events-none"
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default CaseStudy;
