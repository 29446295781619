import divimg from "../assets/misc/divider.svg";
import botdiv from "../assets/misc/divider-bottom.svg";
import heart from "../assets/icons/heart.svg";
import data from "../assets/icons/data.svg";
import notes from "../assets/icons/notes.svg";
import tree from "../assets/icons/tree.svg";

const Solutions = () => {
  return (
    <section
      className="py-16 md:py-28 relative"
      style={{
        backgroundImage: `url(${divimg})`,
        backgroundPosition: "top",
        backgroundSize: "fit",
        backgroundRepeat: "no-repeat",
      }}
    >
      <img src={botdiv} className="absolute bottom-5" alt="" />
      <div className="container mx-auto relative text-center flex flex-col gap-y-28">
        <h2 className="md:block hidden">Wizardry In Marketing Solutions</h2>
        <h2 className="md:hidden block">Wizardry in Ad Solutions</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
          <div className="flex relative">
            <img
              src={heart}
              className="md:w-42 w-[100px] right-0 -top-10 absolute z-10 md:-left-[37px] md:-top-[30px]"
              alt=""
            />
            <div className="card md:!pl-20 text-left">
              <h3 className="text-xl md:text-3xl !text-left">Client-Focused Exellence</h3>
              <p className="md:text-lg leading-snug md:leading-normal mt-3 md:mt-5">
                Prioritizing personalized service, fostering strong
                relationships, exceeding expectations.
              </p>
            </div>
          </div>
          <div className="flex relative">
            <img
              src={notes}
              className="md:w-42 w-[100px] right-0 -top-10 absolute z-10 md:-left-[37px] md:-top-[30px]"
              alt=""
            />
            <div className="card md:!pl-20 text-left">
              <h3 className="text-xl md:text-3xl !text-left">Design Mastery</h3>
              <p className="md:text-lg leading-snug md:leading-normal mt-3 md:mt-5">
                Crafting distinctive design strategies that uniquely resonate,
                aligning brand vision with market demands.
              </p>
            </div>
          </div>
          <div className="flex relative">
            <img
              src={tree}
              className="md:w-42 w-[100px] right-0 -top-10 absolute z-10 md:-left-[37px] md:-top-[30px]"
              alt=""
            />
            <div className="card md:!pl-20 text-left">
              <h3 className="text-xl md:text-3xl !text-left">Market Growth Expertise</h3>
              <p className="md:text-lg leading-snug md:leading-normal mt-3 md:mt-5">
                Tailored strategies foster expansion, nurturing brands through
                seasoned market initiatives.
              </p>
            </div>
          </div>
          <div className="flex relative">
            <img
              src={data}
              className="md:w-42 w-[100px] right-0 -top-10 absolute z-10 md:-left-[37px] md:-top-[30px]"
              alt=""
            />
            <div className="card md:!pl-20 text-left">
              <h3 className="text-xl md:text-3xl !text-left">Insightful Analytics</h3>
              <p className="md:text-lg leading-snug md:leading-normal mt-3 md:mt-5">
                Data-derived insights steer decisions, optimizing campaigns and
                unveiling growth opportunities.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Solutions;
