import Heroimg from "../assets/artwork/hero.svg";
import Bg from "../assets/misc/landing.svg";
import flare from "../assets/misc/flares.png";

const Hero = () => {
  return (
    <section
      className="md:pt-[300px] md:-mt-[230px] pt-[160px] -mt-[160px] h-[1023px] md:overflow-hidden"
      style={{
        backgroundImage: `url(${Bg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <img src={flare} className="absolute top-0 h-[800px] md:h-fit" alt="" />
      <div className="white container mx-auto relative z-20">
        <div className="md:grid grid-cols-3 flex flex-col gap-y-16">
          <div className="col-span-2 flex flex-col gap-y-7 mt-16">
            <h1 className="leading-[1.2]">
              <span className="text-[#0da4df]">AdMiracle</span>, Elevating
              Brands through Creative Ads
            </h1>
            <p className="leading-[1.7]">
              Where Artistry Meets Strategy: Transforming Brands into
              Unforgettable Stories with Innovative Design and Advertising
              Expertise
            </p>
            <p className="leading-[1.7] hidden md:flex">
              Crafting Tailored Solutions for Everyone's Unique Story
            </p>
            <button>
              <h3>GET STARTED TODAY</h3>
              <p>Unlock Your Marketing Potential</p>
            </button>
          </div>
          <img
            src={Heroimg}
            className="md:h-[670px] md:absolute right-[20px] pointer-events-none"
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
