import bioimg from "../assets/misc/biography.svg";
import bio from "../assets/artwork/biography.svg";

const Biography = () => {
  return (
    <section
      className="md:flex flex-col items-center h-[1036px] justify-center py-28 md:py-0"
      style={{
        backgroundImage: `url(${bioimg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="white container mx-auto relative">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-20">
          <div className="">
            <img
              src={bio}
              className="md:absolute left-[40px] top-10 w-[730px]"
              alt=""
            />
          </div>
          <div className="flex flex-col gap-y-7">
            <h2>Biography</h2>
            <p className="leading-[1.7]">
              At AdMiracle, we've collaborated with top-notch professionals to
              generate substantial returns on ad spend. We eliminate the
              inefficiencies and bureaucratic hurdles commonly found in many
              agencies.
            </p>
            <p className="leading-[1.7]">
              Our client success stories have reached legendary status, our
              queue for new clients surpasses the list of grievances you may
              have with your current agency, and we seek a particular type of
              client.
            </p>
            <p className="leading-[1.7] font-extrabold">
              Perhaps that client is you...
            </p>
            <button>
              <h3>GET STARTED TODAY</h3>
              <p>Unlock Your Marketing Potential</p>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Biography;
