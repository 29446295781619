import divimg from "../assets/misc/divider.svg";

const Clients = () => {
  return (
    <section
      className="py-10 md:py-28 pt-20"
      style={{
        backgroundImage: `url(${divimg})`,
        backgroundPosition: "top",
        backgroundSize: "fit",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="container mx-auto relative text-center flex flex-col gap-y-10 md:gap-y-28">
        <h2 className="!text-center">Trusted by Leading Enterprises</h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
          <div className="card">a</div>
          <div className="card">a</div>
          <div className="card">a</div>
          <div className="card">a</div>
        </div>
      </div>
    </section>
  );
};

export default Clients;
