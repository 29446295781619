import divimg from "../assets/misc/divider.svg";
import botdiv from "../assets/misc/divider-bottom.svg";
import { TbChevronUp } from "react-icons/tb";

const Faq = () => {
  return (
    <section
      className="py-10 md:py-28 relative"
      style={{
        backgroundImage: `url(${divimg})`,
        backgroundPosition: "top",
        backgroundSize: "fit",
        backgroundRepeat: "no-repeat",
      }}
    >
      <img src={botdiv} className="absolute bottom-5" alt="" />
      <div className="container mx-auto relative text-center flex flex-col gap-y-28">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 md:text-left">
          <div>
            <h2>
              Frequently Asked <span className="text-main">Questions</span>
            </h2>
            <div className="md:block hidden">
              <p className="text-xl font-normal leading-normal mt-5">
                Couldn't find what you were looking for? <br /> <br />
                <span className="font-medium text-main underline cursor-pointer">ousebk@gmail.com</span>
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <div className="card">
              <div className="flex items-center justify-between">
                <h3 className="text-lg md:text-2xl">Client-Focused Exellence</h3>
                <TbChevronUp />
              </div>
              <p className="text-base md:text-lg leading-snug md:leading-normal mt-2">
                Prioritizing personalized service, fostering strong
                relationships, exceeding expectations.
              </p>
            </div>
            <div className="card">
              <div className="flex items-center justify-between">
                <h3 className="text-lg md:text-2xl">Client-Focused Exellence</h3>
                <TbChevronUp className="transform rotate-180" />
              </div>
            </div>
            <div className="card">
              <div className="flex items-center justify-between">
                <h3 className="text-lg md:text-2xl">Client-Focused Exellence</h3>
                <TbChevronUp className="transform rotate-180" />
              </div>
            </div>
            <div className="card">
              <div className="flex items-center justify-between">
                <h3 className="text-lg md:text-2xl">Client-Focused Exellence</h3>
                <TbChevronUp className="transform rotate-180" />
              </div>
            </div>
            <div className="card">
              <div className="flex items-center justify-between">
                <h3 className="text-lg md:text-2xl">Client-Focused Exellence</h3>
                <TbChevronUp className="transform rotate-180" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
