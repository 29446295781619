import bg from "../assets/misc/footer.svg";

const Footer = () => {
  return (
    <section
      className="flex items-center -mb-[40px] h-[766px]"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="white container mx-auto relative">
        <div className="flex flex-col gap-y-12 text-center items-center ">
          <h1>Have An Inquiry?</h1>
          <p className="leading-[1.7]">
            If you have a general inquiry and would like to speak to our expert{" "}
            <br />
            team, you can contact us via email at: <span className="text-main font-semibold underline">ousebk@gmail.com</span>
          </p>

          <button>
            <h3>GET STARTED TODAY</h3>
            <p>Unlock Your Marketing Potential</p>
          </button>
          <div>
            <h4 className="text-base font-extrabold">
              Eunoia Online Services - Company Number: 11905597
            </h4>
            <h4 className="text-base mt-3">
              All Rights Reserved | All Wrongs Reversed
            </h4>
          </div>
          <h4 className="text-base">
            Terms & Conditions | Privacy Policy | Disclaimer
          </h4>
        </div>
      </div>
    </section>
  );
};

export default Footer;
